<template>
  <div style="background: #FFFFFF 0% 0% no-repeat padding-box;">
    <!-- <img src="@/assets/images/RNewSlider.png" width="100%" :style="isMobile ? {} : { height: '600px' }" alt="" /> -->
    <!-- <div>
    <img src="@/assets/images/RNewSlider.png" style="
                                                 top: 100px;
                                                 left: 0;
                                                 width: 1920px;
                                                 height: 795px;
                                                 background: transparent url('@/assets/images/RNewSlider.png') 0% 0% no-repeat padding-box;
                                                opacity: 1;
                                                " />
   </div> -->
    <div style="position: relative; text-align: center;">
      <img src="@/assets/images/RNewSlider.png" style="
                                                 top: 100px;
                                                 left: 0;
                                                 width: 1900px;
                                                 height: 700px;
                                                opacity: 1;
                                                " />

      <img src="@/assets/images/siledpannar.png" style="
                                                 position: absolute;
                                                 top: 10%;
                                                 transform: translateX(-50%);
                                                 /* width: 516px;
                                                 height: 546px; */
                                                 " />
    </div>



    <!-- /assets/images/disabled-mind.png -->
    <div class="mt-2 pt-10 pb-4" style="background: #FFFFFF 0% 0% no-repeat padding-box; margin-left: 60px;">
      <h3 style="position: static; margin-right: 40px;">
        {{ $t("searchBySpeciality") }}
      </h3>
      <div class="row">
        <div class="card" @click="GoToIsOnlineDoctors">
          <img src="@/assets/images/onlineDoctor.png" alt="Online Doctor" class="card-img" />
          <h2 class="card-title">{{ $t("OnlineDoctor") }}</h2>
          <p class="card-desc">{{ $t("OnlineDoctorDes") }}</p>
          <a href="#" class="see-more">
            See More <span class="arrow">&rarr;</span>
          </a>
        </div>
        <div class="card">
          <img src="@/assets/images/medical.png" alt="Medical Specialties" class="card-img" />
          <h2 class="card-title">{{ $t("MedicalSpecialties") }}</h2>
          <p class="card-desc">{{ $t("MedicalSpecialtiesDes") }}</p>
          <a href="#" class="see-more">
            See More <span class="arrow">&rarr;</span>
          </a>
        </div>
        <div class="card">
          <img src="@/assets/images/othermedical.png" alt="Allied Medical Specialties" class="card-img" />
          <h2 class="card-title">{{ $t("AlliedMedicalSpecialties") }}</h2>
          <p class="card-desc">{{ $t("AlliedMedicalSpecialtiesDes") }}</p>
          <a href="#" class="see-more">
            See More <span class="arrow">&rarr;</span>
          </a>
        </div>
      </div>
    </div>
    <!-- OurDoctor -->
    <!-- <div class="mycontainercustom" :class="isMobile ? 'mt-5' : ''" style="margin-bottom: 4%;">
   
      <img width="100%" src="@/assets/images/ourdoctor.png" alt="" style="margin-top: 2%; background-color: #21C5F2; " />
      <div v-if="!isMobile" :class="!isMobile && !$vs.rtl ? 'CustomtextSliderpart2' : $vs.rtl ? 'CustomtextSliderpart2Arabic' : ''">
        <h3 style="font-weight: bold; color: black" >
          {{ $t("OurDoctor") }}
        </h3>
         <h5 style="font-weight: bold; color: white; text-align: left" :style="isMobile ? {} : { 'margin-top': '5%' }">
          we have the best medical team specialized in different medical fields. Get to know our team.
        </h5>
        <span class="">
          <a href="#" class="see-morenew"
            style="text-align: center;  background-color: green; color: white; border-radius: 5px;">
            See More
          </a>
        </span> 
      </div>

    </div> -->

    <!-- <div class="mycontainercustom" :class="isMobile ? 'mt-5' : ''" style="margin-bottom: 4%;">
  <div style="position: relative; display: inline-block;">
    <img width="100%" src="@/assets/images/ourdoctor.png" alt="" style="margin-top: 2%; background-color: #21C5F2; " />
    <div style="position: absolute; top: 20%; left: 50%; transform: translate(-50%, -50%);">
      <h3 style="font-weight: bold; color: #113E72">
        {{ $t("OurDoctor") }}
      </h3>
      <h5 style="font-weight: bold; color: #113E72; text-align: left" :style="isMobile ? {} : { 'margin-top': '5%' }">
          we have the best medical team specialized in different medical fields. Get to know our team.
        </h5>
          <a href="#" class="Show-more"  @click="$router.push('/AppointmentSearch/')">
            Show More
          </a>
    </div>
  </div>
</div> -->
    <div class="mycontainercustom" :class="isMobile ? 'mt-5' : ''" style="margin-bottom: 4%;">
      <div style="position: relative; display: inline-block;">
        <img width="100%" src="@/assets/images/ourdoctor.png" alt=""
          style="margin-top: 2%; background-color: #21C5F2; " />
        <div style="position: absolute; top: 20%; left: 50%; transform: translate(-50%, -50%);">
          <h3 style="font-weight: bold; color: #113E72; text-align: left"
            :style="isMobile ? {} : { 'margin-top': '5%', 'position': 'absolute', 'top': '40%', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
            {{ $t("OurDoctor") }}
          </h3>
          <h5 style="font-weight: bold; color: #113E72; text-align: left; white-space: nowrap;"
            :style="isMobile ? {} : { 'margin-top': '4%', 'position': 'absolute', 'top': '47%', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
            we have the best medical team specialized in different medical fields. Get to know our team.
          </h5>
          <p href="#" class="p-1 rounded" @click="$router.push('/AppointmentSearch/')" :style="isMobile ? {} : {
            'margin-top': '6%',
            'position': 'absolute', 'top': '51%', 'left': '50%', 'transform': 'translate(-50%, -50%)',
            'justify-content': 'space-between', 'color': 'white', 'border-radius': '3px',
            'background-color': '#698F3F', 'font-weight': 'bold', 'font-size': ' 15px', 'text-align': 'left', ' margin-top': '30%', 'width': 'max-content',
          }">
            Show More
          </p>
          <img width="100%" src="@/assets/images/ourdoctors.png" alt="" style="margin-top: 69%;" />
        </div>
      </div>
    </div>

    <!-- top doctors -->
    <!-- <div class="mt-5">
      <h3 class="mb-2" style="text-align: center; color: black; font-weight: bold">
        {{ $t("TopDoctors") }}
      </h3>
      <b-row>
        <b-col cols="12" md="2"></b-col>
        <b-col cols="12" md="2" v-for="(doctor, index) in doctors" v-if="index < 4" :key="index.ID">
          <b-card>
            <div @click="$router.push('/DoctorProfile/' + doctor.ID)" class="d-flex"
              style="width: 100%; height: 200px; position: relative">
              <img style="
                                                              display: block;
                                                              width: auto;
                                                              height: 200px;
                                                              text-align: center;
                                                            " class="card center" v-if="doctor.ProfileImagePath"
                :src="baseURL + doctor.ProfileImagePath" alt="Car image" />
              <img style="
                                                              display: block;
                                                              width: auto;
                                                              height: 200px;
                                                              text-align: center;
                                                            " class="card center" v-else
                src="@/assets/images/DoctorPlaceHolder.png" alt="Car image" />
            </div>
            <p v-if="doctor.Name" class="m-2" style="text-align: center; color: #2753d9">
              {{ $t("Dr") }}.
              {{ doctor.Name.substring(0, 17) }}
            </p>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="doctors && doctors.length > 0">
        <b-col cols="12" md="5"></b-col>
        <b-col><b-button @click="$router.push('/AppointmentSearch/')" variant="secondary" style="text-align: center"
            md="6">
            {{ $t("ShowMore") }}
          </b-button></b-col>
      </b-row>
    </div> -->

    <!-- <div class="mt-4">
      <h3 class="mb-2" style="text-align: center; color: black; font-weight: bold">
        {{ $t("TopDoctors") }}
      </h3>
      <div class="custom-row-styl">
        <div class="col-md-4"></div>
        <div class="col-md-4 col-sm-5" v-for="(doctor, index) in doctors" :key="index.ID">
          <div class="mb-2 custom-card-style">
            <div @click="$router.push('/DoctorProfile/' + doctor.ID)">
              <img style=""  v-if="doctor.ProfileImagePath"
                :src="baseURL + doctor.ProfileImagePath" alt="Doctor Image" />
              <img   v-else
                src="@/assets/images/MaskDoctor.svg" alt="Doctor Image" />
            </div>
            <div class="custom-card-body">
              <h5 class="custom-card-title mb-0" >
                {{ $t("Dr") }} {{ doctor.Name }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div v-if="doctors && doctors.length > 0" >
          <button @click="$router.push('/AppointmentSearch/')" class="Borwse-all" >
            {{ $t("BrowseAll") }}
          </button>
       
      </div>
    </div> -->

    <div>
      <div class="mt-2 p-0">
        <h3 style="text-align: center;color: black; font-weight: bold; display: block;">
          {{ $t("TopDoctors") }}
        </h3>
      </div>
      <div class="parent-div">
        <div class="custom-row-style">
          <div class="custom-card-container" v-for="(doctor, index) in doctors" :key="index.ID">
            <div class="mb-2 custom-card-style">
              <div @click="$router.push('/DoctorProfile/' + doctor.ID)">
                <img style="" v-if="doctor.ProfileImagePath" :src="baseURL + doctor.ProfileImagePath"
                  alt="Doctor Image" />
                <img v-else src="@/assets/images/MaskDoctor.svg" alt="Doctor Image" />
              </div>
              <div class="custom-card-body">
                <h5 class="custom-card-title mb-0">
                  {{ $t("Dr") }} {{ doctor.Name }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="mycontainer" :class="isMobile ? 'mt-5' : ''" style="margin-bottom: 4%;">
      <div style="position: relative; display: inline-block;">
        <img width="100%" src="@/assets/images/overlay.png" alt="" style="margin-top: 2%; " />
        <div style="position: absolute; top: 20%; left: 50%; transform: translate(-50%, -50%);">
          <div v-if="!isMobile"
            :class="!isMobile && !$vs.rtl ? 'textSliderpart2' : $vs.rtl ? 'textSliderpart2Arabic' : ''">
            <h1 style="font-weight: bold; color:white ; text-align: left;white-space: nowrap;">{{ $t("Our Mobile Applications") }}</h1>
            <p style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
              :style="isMobile ? {} : { 'margin-top': '0', 'position': 'absolute', 'top': '50%', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
              {{ $t("Now You can download our new application for better experience") }}
            </p>

          </div>
        </div>
      </div>
    </div> -->
    <!-- 
    <div class="mycontainercustom" :class="isMobile ? 'mt-5' : ''" style="margin-bottom: 4%;">
      <div style="position: relative; display: inline-block;">
        <img width="100%" src="@/assets/images/overlay.png" alt=""
          style="margin-top: 2%; background-color: #21C5F2; " />
          <div>
            <div style="position: absolute; top: 10%; left: 50%; transform: translate(-50%, -50%);">
          <h3 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
            :style="isMobile ? {} : { 'margin-top': '5%', 'position': 'absolute',  'left': '50%', 'transform': 'translate(-50%, -50%)' }">
            {{ $t("Our Mobile Applications") }}
          </h3>
        </div>
          <div style="position: absolute; top: 14%; left: 50%; transform: translate(-50%, -50%);">
            <h5 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
            :style="isMobile ? {} : { 'margin-top': '8%', 'position': 'absolute', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
            {{ $t("Now You can download our new application for better experience") }}
          </h5> 
          </div>
          </div>        
      </div>
    </div> -->

    <!-- <div class="mycontainercustom" :class="isMobile ? 'mt-5' : ''"
      style="margin-bottom: 4%; background-image: url('@/assets/images/Ellipse.png'), url('@/assets/images/Ellipse2.png'); background-repeat: no-repeat; background-position: left top, right bottom;">
      <div style="position: relative; display: inline-block;">
        <img width="100%" src="@/assets/images/overlay.png" alt="" style="margin-top: 2%; background-color: #21C5F2; " />
        <div>
          <div style="position: absolute; top: 10%; left: 50%; transform: translate(-50%, -50%);">
            <h3 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
              :style="isMobile ? {} : { 'margin-top': '5%', 'position': 'absolute', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
              {{ $t("Our Mobile Applications") }}
            </h3>
          </div>
          <div style="position: absolute; top: 14%; left: 50%; transform: translate(-50%, -50%);">
            <h5 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
              :style="isMobile ? {} : { 'margin-top': '8%', 'position': 'absolute', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
              {{ $t("Now You can download our new application for better experience") }}
            </h5>
          </div>
        </div>
        <img src="@/assets/images/Ellipse.png" style="
                                                 position: absolute;
                                                 top: 19%;
                                                 left: 37%;
                                                 transform: translateX(-50%);
                                                 width: 508px;
                                                 height: 470px;
                                                 " />
        <img src="@/assets/images/Ellipse2.png" style="
                                                 position: absolute;
                                                 top: 19%;
                                                 right: 33%;
                                                 transform: translateX(50%);
                                                 width: 508px;
                                                 height: 470px;
                                                 " />
      </div>
    </div> -->
<!-- 
     <div class="mycontainercustom" :class="isMobile ? 'mt-5' : ''"
      style="margin-bottom: 4%; background-image: url('@/assets/images/Ellipse.png'), url('@/assets/images/Ellipse2.png'); background-repeat: no-repeat; background-position: left top, right bottom;">
      <div style="position: relative; display: inline-block;">
        <img width="100%" src="@/assets/images/overlay.png" alt="" style="margin-top: 2%; background-color: #21C5F2; " />
        <div>
          <div style="position: absolute; top: 10%; left: 50%; transform: translate(-50%, -50%);">
            <h3 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
              :style="isMobile ? {} : { 'margin-top': '5%', 'position': 'absolute', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
              {{ $t("Our Mobile Applications") }}
            </h3>
          </div>
          <div style="position: absolute; top: 14%; left: 50%; transform: translate(-50%, -50%);">
            <h5 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
              :style="isMobile ? {} : { 'margin-top': '8%', 'position': 'absolute', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
              {{ $t("Now You can download our new application for better experience") }}
            </h5>
          </div>
        </div>
        <img src="@/assets/images/ourmobileapps.png" style="
                                                 position: absolute;
                                                 top: 19%;
                                                 transform: translateX(-50%);
                                                 width: 83%;
                                                 height: 83%;
                                                 " />

      </div>
    </div> -->

    <div class="mycontainercustom" :class="isMobile ? 'mt-5' : ''"
      style="margin-bottom: 4%; background-image: url('@/assets/images/Ellipse.png'), url('@/assets/images/Ellipse2.png'); background-repeat: no-repeat; background-position: left top, right bottom;">
      <div style="position: relative; display: inline-block;">
        <img width="100%" src="@/assets/images/overlay.png" alt="" style="margin-top: 2%; background-color: #21C5F2; " />
        <div>
          <div style="position: absolute; top: 10%; left: 50%; transform: translate(-50%, -50%);">
            <h3 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
              :style="isMobile ? {} : { 'margin-top': '5%', 'position': 'absolute', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
              {{ $t("Our Mobile Applications") }}
            </h3>
          </div>
          <div style="position: absolute; top: 14%; left: 50%; transform: translate(-50%, -50%);">
            <h5 style="font-weight: bold; color: white; text-align: left; white-space: nowrap;"
              :style="isMobile ? {} : { 'margin-top': '8%', 'position': 'absolute', 'left': '50%', 'transform': 'translate(-50%, -50%)' }">
              {{ $t("Now You can download our new application for better experience") }}
            </h5>
          </div>
        </div>
        <img src="@/assets/images/ourmobileapps.png" style="
                                                 position: absolute;
                                                 top: 19%;
                                                 transform: translateX(-50%);
                                                 width: 83%;
                                                 height: 83%;
                                                 " />

<div style="position: absolute; bottom: 15%; left: 0; right: 0; margin: 0 auto; width: 50%;">
  <button style="float: left; background-color: white; color: #21C5F2; padding: 10px 20px; border-radius: 5px; font-weight: bold; border: none; margin-right: 10px;">{{ $t("Download Now") }}</button>
  <button style="float: right;background-color: #21C5F2; color: white; padding: 10px 20px; border-radius: 5px; font-weight: bold; border: none;">{{ $t("Download Now") }}</button>
</div>

      </div>
    </div>

  </div>
</template>
<script>

import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import FeatherIcon from "../components/FeatherIcon.vue";

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";
export default {
  components: {
    FeatherIcon,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormTextarea,
    BRow,
  },
  data() {
    return {
      IsHelper: true,
      IsGeneral: true,
      IsOnline: 1,
      baseURL: domain,
      CurrentPage: 1,
      CountPerPage: 6,
      search: {},
    };
  },
  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    doctors() {
      debugger
      return this.$store.state.DoctorList.doctors;
    },
    specialties() {
      return this.$store.state.SpecialtyList.specialties;
    },
    countries() {
      debugger
      return this.$store.state.CountryList.countries;
    },
    topDestinationsPage() {
      debugger
      return this.$store.state.CountryList.topDestinations.slice(
        (this.CurrentPage - 1) * this.CountPerPage,
        (this.CurrentPage - 1) * this.CountPerPage + this.CountPerPage
      );
    },
    hospitals() {
      return this.$store.state.HospitalList.searchSimpleHospitals;
    },
    topDestinations() {
      debugger
      return this.$store.state.CountryList.topDestinations;
    },
  },
  created() {
    debugger;
    if (!moduleHospital.isRegistered) {
      debugger;
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    this.$store.dispatch("DoctorList/SimpleSearchDoctors", {});
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    this.$store.dispatch("CountryList/GetTopDestinations");
    this.$store.dispatch("CountryList/GetAllCountries");


    this.$store.dispatch("HospitalList/SimpleSearchHospitals", {})
  },
  methods: {
    GoToIsGeneralDoctors() {
      const search = { IsGeneral: 1 };
      this.$router.push('/AppointmentSearchFiltter/0/' + search.IsGeneral + '/0');
    },
    GoToIsOnlineDoctors() {
      const search = { IsOnline: 1 };
      this.$router.push('/AppointmentSearchFiltter/0/' + '0/' + search.IsOnline);
    },
    GoToIIsHelperDoctors() {
      const search = { IsGeneral: 1 };
      this.$router.push('/AppointmentSearchFiltter/' + search.IsHelper + '/0' + '/0');
    },
  },
  beforeCreate() {
    if (this.$acl.check("doctor")) this.$router.push("/doctor/Home");
    else if (this.$acl.check("hospital")) this.$router.push("/hospital/Home");
  },
};
</script>

<style>
.textSliderpart2 {
  position: absolute;
  color: white;
  margin-top: -20%;
  text-align: left;
  left: 10%;
}

.textSliderpart2Arabic {
  position: absolute;
  color: white;
  margin-top: -20%;
  text-align: right;
  right: 5%;
}

#hoverButton:hover {
  border: 3px solid #16538C;
  color: #16538C;
}
</style>


<style scoped>
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.see-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #698F3F;
  padding: 10px;
  color: white;
  text-decoration: none;
  position: relative;
  border-radius: 10px;
}

.Show-more {

  position: static;
  justify-content: space-between;
  background-color: #698F3F;
  top: 50%;
  left: 50%;
  width: 50vw;
  height: 50vh;
  color: white;
  border-radius: 3px;
  transform: translate(-50%, -50%);
  opacity: 1;

}

.arrow {
  position: absolute;
  right: 10px;
  font-size: 18px;
  z-index: 1;
}

.card {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #698F3F7E;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  margin: 0 10px;
  text-align: center;
  padding: 20px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  position: relative;
  border-radius: 10px;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 5px;
  height: 5px;
  width: 10%;
  background-color: #0E2459;
  border-radius: 10px;
  z-index: 1;
}

.Borwse-all {
  align-items: center;
  background-color: green;
  color: white;
  position: relative;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 310px;
  height: 45px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 10px #698F3F;
}

.card img {
  width: 100%;
  height: auto;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0E2459;
  margin-top: 10px;
}

.card-desc {
  font-size: 1.2rem;
  padding-top: 1%;
  color: black;
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: center;
  }
}
</style>

<style scoped>
.mycontainer {
  text-align: center;
}

.parent-div {
  display: center;
  align-items: center;
  /* justify-content: center; */
  height: 100vh;
  width: 100%;
  max-width: 100%;
}

.custom-row-style {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;

}

.custom-card-container {
  width: 22%;
  height: 15%;
  margin-bottom: 2%;
  box-sizing: border;
}

.custom-card-style {
  background-color: #21C5F2;
  margin-right: 5px;
  margin-top: 10%;
  margin-left: 5px;
  position: static;
  height: 50%;
  width: 80%;
  border-radius: 0;
  overflow: hidden;
  text-align: center;
}

.custom-card-style img {
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 75%;
}

.custom-card-style::before {
  content: "";
  position: absolute;
  top: 0;
  left: 30%;
  transform: translateX(-70%);
  height: 5px;
  width: 10%;
  background-color: #0E2459;
  border-radius: 10px;
  z-index: 1;
}

.custom-card-body {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-card-title {
  font-size: small;
  white-space: nowrap;
  text-align: center;
  background-color: #0E2459;
  color: white;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-top: 0;
}

.CustomtextSliderpart2 {
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
}

.CustomtextSliderpart2Arabic {
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
}

.-card-style .card-body {
  padding: 10px;
}

.mycontainercustom {
  text-align: center;
  justify-content: center;
}
</style>




<style>
/* Media queries */
@media screen and (max-width: 768px) {

  /* Adjust the size and spacing of the images */
  div[style*='flex']>div {
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {

  /* Adjust the size and spacing of the images */
  div[style*='flex']>div {
    padding: 5px;
  }
}
</style>